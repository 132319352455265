import React from 'react'
import SubTermsCondition from '../SubTermsCondition'

const UsertermsCondition = ({ show, close }) => {
  return (
    <>
    
       <SubTermsCondition close={close}/>

    </>
  )
}

export default UsertermsCondition