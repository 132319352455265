import React from 'react'
import MearchantHeader from './MearchantHeader'
import PageBanner from '../PageBanner'
import Cupons from './Desktop/Cupons'
import DraftCupon from './Desktop/DraftCupon'
import Statistics from './Desktop/Statistics'
import CreateOperatorlogin from './Desktop/CreateOperatorlogin'
import Subscription from './Desktop/Subscription'

const MerchantDesktop = () => {
  return (
    <>
    <MearchantHeader/>
      <PageBanner title='Merchant'/>
      <Cupons/>
      <DraftCupon/>
      <Subscription/>
      <Statistics/>
      <CreateOperatorlogin/>
    </>
  )
}

export default MerchantDesktop
