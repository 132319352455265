import React, { useEffect, useState } from 'react'

const Expenses = () => {
    // const items = [
    //     {
    //         name: 'Basic ',
    //         img: 'assets/Image/sub-1.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Premium ',
    //         img: 'assets/Image/subscriptionmodel.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Premium ',
    //         img: 'assets/Image/subscriptionmodel.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Basic ',
    //         img: 'assets/Image/sub-1.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Premium ',
    //         img: 'assets/Image/subscriptionmodel.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Premium ',
    //         img: 'assets/Image/subscriptionmodel.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Basic ',
    //         img: 'assets/Image/sub-1.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Premium ',
    //         img: 'assets/Image/subscriptionmodel.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Premium ',
    //         img: 'assets/Image/subscriptionmodel.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Basic ',
    //         img: 'assets/Image/sub-1.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Premium ',
    //         img: 'assets/Image/subscriptionmodel.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    //     {
    //         name: 'Premium ',
    //         img: 'assets/Image/subscriptionmodel.png',
    //         startdate: '22/02/2023',
    //         enddate: '22/02/2023',
    //         cuponlimit: '20',
    //         wallet: '100',
    //         rupees: '1,000'
    //     },
    // ]

    const [items, setItems] = useState([])

    useEffect(() => {

        const token = sessionStorage.getItem('token');
        const userData = JSON.parse(token);
        const id = userData.id;

        const formData = {
            user_id: id
        }

        console.log(formData)
        fetch('https://www.demo603.amrithaa.com/camdell/appapi/getexpense.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                  },
                body: JSON.stringify(formData)
            })

            .then(response => response.json())

            .then(data => {

                setItems(data.data.purchased);
                console.log(data.data);

            })

            .catch(error => {

                console.error('API error:', error);

            });

    }, []);
    return (
        <>
            <div className="expenses-outer">
                <div className="container">
                    <div className="expenses-inner">
                        <div className="row">
                            {
                               items  && items.map((i) => {
                                    return (
                                        <div className="subcription-item col-xl-4 col-md-6  col-12">
                                            <div className="item-inner">
                                                <div className="img-content">
                                                    <img src={i.img} alt="" />
                                                </div>
                                                <div className="text-content">
                                                    <h3>{i.name} Subscription plan</h3>
                                                    <div className="date-info">
                                                        <p className='start-date'>Start On : {i.startdate}</p>
                                                        <p className='end-date'>End On : {i.enddate}</p>
                                                    </div>
                                                    <div className="cupon-limit">
                                                        Coupon limited  : {i.cuponlimit} Coupons
                                                    </div>
                                                    <div className="wallet">
                                                        <div className="usedwallet">Used Wallet  : Rs {i.wallet}</div>
                                                        <p><span className='ruppes-icon'>₹ </span>1000</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Expenses