import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react'
import { Container, Dropdown, DropdownItem, Modal, Navbar } from 'react-bootstrap'
import { FaRegBell, FaSearch, FaUserAlt } from 'react-icons/fa';
import { IoLogOut } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import Notification from '../User/Notification';

const ShoperHeader = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleClose = () => setShowModal(false);
    const [profileImage, setProfileImage] = useState(null);

    useEffect(() => {
        const storedToken = sessionStorage.getItem('token');
        if (storedToken) {
            const tokenData = JSON.parse(storedToken)
            setProfileImage(tokenData.profile_image);
        }
    }, []);

    const handlenotificition = () => {
        setModalContent(<Notification onClose={handleClose} />)
        setShowModal(true);
    }

    return (
        <>
            <Navbar expand="lg" className="merchantheader">
                <div className="top">
                    <Container>
                        <div className="header-inner row">


                            <div className="logo col-2">
                                <div className="img-content">
                                    <img src="assets/Image/logo.png" alt="Header Logo" />

                                </div>
                            </div>
                            <div className="info col-6">
                                <div className="search">
                                    <input type="text" name="search" id="search" placeholder='Search for the coupons , deals and  offers' />
                                    <button><FaSearch /></button>
                                </div>
                                <div className="subscribe">
                                    <Link onClick={handlenotificition}><FaRegBell />
                                    </Link>
                                </div>
                                <div className="avtar-img">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            {profileImage ? (
                                                <img src={`https://www.demo603.amrithaa.com/camdell/public/images/${profileImage}`} alt="User Profile" />
                                            ) : (
                                                <Avatar size={50} icon={<FaUserAlt />} />
                                            )}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <DropdownItem as={Link} to=""><IoLogOut /> Logout</DropdownItem>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </Navbar >

            <Modal show={showModal} onHide={handleClose}>
                {modalContent}
            </Modal>
        </>
    )
}

export default ShoperHeader
