import React from 'react'
import PageBanner from '../PageBanner'
import CreateOperatorlogin from './Desktop/CreateOperatorlogin'
import Cupons from './Desktop/Cupons'
import DraftCupon from './Desktop/DraftCupon'
import Statistics from './Desktop/Statistics'
import Subscription from './Desktop/Subscription'
import OperatorHeader from './OperatorHeader'


const OperatorDesktop = () => {
  return (
    <>
    <OperatorHeader/>
      <PageBanner title='Manager'/>
      <Cupons/>
      <DraftCupon/>
      <Subscription/>
      <Statistics/>
      <CreateOperatorlogin/>
    </>
  )
}

export default OperatorDesktop
