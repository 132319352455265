import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const CuponCodeEnter = ({ onOpenUserCupon }) => {

    const [couponCode, setCouponCode] = useState('');

    const handleCouponCodeChange = (e) => {

        setCouponCode(e.target.value);
    
      };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {

        const token = sessionStorage.getItem('token');
            const userData = JSON.parse(token);
            const userId = userData.id;

        const formData = {
            user_id : userId,
            code :couponCode
        }

        console.log(formData)
      const response = await fetch('https://www.demo603.amrithaa.com/camdell/appapi/couponsubmit.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: (formData),
      });
      const data = await response.json();
      if(data.success){
        console.log(data);
      // Handle the response data
      onOpenUserCupon();
      }
      else {
        console.log(data)
      }
      
    } catch (error) {
      console.error(error);
    }

  };

    return (
        <>
            <div className="enter-cupon-code">
                <div className="cupon-code-info">
                    <div className="heading"><h3>Enter coupon code</h3></div>
                    <input type="text" placeholder='Enter coupon code'  value={couponCode} onChange={handleCouponCodeChange}/>
                </div>

                <div className="submit-button">
                    <Link className="button" onClick={handleSubmit}>Submit</Link>
                </div>
            </div>
        </>
    )
}

export default CuponCodeEnter
